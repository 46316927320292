<template>
  <div>
    <v-card flat class="mx-auto my-12" max-width="90vw">
      <v-progress-linear color="indigo" height="10" indeterminate v-if="loading">
      </v-progress-linear>
      <v-card-title class="justify-center text-h4">
        חפש מודעה
      </v-card-title>
      <showFilteredPosts v-if="startPostID != ''" :initiatedPostTypeId="startPostID" />
      <showFilteredPosts v-else />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


import showFilteredPosts from '@/components/showRecord/showFilteredPosts.vue'
// import NewPosts from "@/components/app/MyLuggage/pages/NewPosts.vue";
export default {
  name: "search",
  data: () => ({
    // loading: false,
    // typePosts: [
    //   { title: "הובלה כללית", icon: "1" },
    //   { title: "הסעות", icon: "2" },
    //   { title: "מעבר דירה", icon: "3" },
    //   { title: "גרר", icon: "4" },
    //   { title: "פינוי פסולת", icon: "5" },
    //   { title: "מנוף", icon: "6" },
    //   { title: "ציוד מכני הנדסי", icon: "7" },
    // ],
  }),
  methods: {
    iconPath() {
      return "@/assets/post_type_icons/7.png";
    },
    getStartPostID() {
      return this.$route.query.search;
    },
  },
  components: {
    // NewPosts,
    showFilteredPosts
  },

  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("lagguageHeaders");
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading"]),
    startPostID() {
      return this.$route.params.search;
    },
  },
};
</script>
